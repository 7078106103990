import invert from 'lodash/invert';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import forIn from 'lodash/forIn';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import sumBy from 'lodash/sumBy';
import fromPairs from 'lodash/fromPairs';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import pick from 'lodash/pick';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import values from 'lodash/values';
import flatMap from 'lodash/flatMap';
import compact from 'lodash/compact';
import {
  PatientPaymentByEncounterDTO,
  EncounterFinancialDetailsDTO,
  BillingCodeViewDTO,
  CustomServiceCodeViewDTO,
  EOBServiceCodePaymentInfoViewDTO,
  EOBDataForClaimViewDTO,
  ClaimAdjustmentViewDTO,
  ClaimDTO,
} from 'dtos';

import {
  VisitNoteBillInfo,
  ServiceAllocation,
  VisitInfo,
} from 'pages/Dashboard/pages/Billing/types';
import {
  formatDate,
  formatDateTime,
} from 'utils/date';
import {
  isEmptyString,
  roundNumber,
} from 'utils/misc';
import {
  PatientCompact,
} from 'pages/Dashboard/pages/Encounters/types/patient';

import {
  ClaimAdjustment,
} from 'pages/Dashboard/pages/Billing/Payments/EOBDialog/EOBAdjustmentCodePopover';
import {
  getFullName,
} from 'utils/string';
import {
  EOBForm,
} from 'pages/Dashboard/pages/Billing/Payments/EOBDialog';

type AdjustmentRelatedField = 'deductible' | 'coinsurance' | 'copay' | 'insuranceWriteOff';
type AdjustmentRelatedInfo= {
  groupCode: string;
  reasonCode: string;
};

export const paymentTypes: string[] = [
  'CheckPayment',
  'CashPayment',
  'CardPayment',
  'ACHPayment',
  'NonePayment',
  'CreditTransferPayment',
];

export const paymentNamesByDiscriminator: Readonly<Record<string, string>> = Object.freeze({
  CheckPayment: 'Check',
  CashPayment: 'Cash',
  CardPayment: 'Card',
  ACHPayment: 'ACH',
  NonePayment: 'None',
  CreditTransferPayment: 'Credit Transfer',
});

export const eobAmountKeys = Object.freeze([
  'allowedAmount',
  'copay',
  'deductible',
  'coinsurance',
  'insurancePayment',
  'nonCovered',
]);

export const keysForAllocatedByType = Object.freeze({
  payment: [
    'copay',
    'nonCovered',
    'deductible',
    'coinsurance',
  ],
  eob: [
    'copay',
    'deductible',
    'coinsurance',
    'nonCovered',
    'insurancePayment',
  ],
  nonCoveredCalculation: [
    'coinsurance',
    'copay',
    'deductible',
    'insurancePayment',
  ],
});

export function normalizeAdjustmentCodes(adjustments: ClaimAdjustmentViewDTO[]) {
  return adjustments?.map(({
    reasonCode,
    groupCode,
    amount,
    quantity,
  }) => ({
    reasonCode: {
      code: reasonCode ?? '',
    },
    groupCode: { code: groupCode ?? '' },
    amount: amount?.toString(),
    quantity,
  }));
}

export function serializeAdjustmentCodes(adjustments: ClaimAdjustment[]) {
  return adjustments?.map(({ reasonCode, groupCode, amount, quantity }) => ({
    reasonCode: reasonCode?.code ?? null,
    groupCode: groupCode?.code ?? null,
    amount: Number(amount) ?? 0,
    quantity: isEmptyString(quantity) ? null : Number(quantity),
  }));
}

export function calculateEOBAllocated(paymentsInfo: EOBServiceCodePaymentInfoViewDTO[]) {
  return sumBy(paymentsInfo, 'insurancePayment');
}

export function serializeEOB({ eob, insurancePayment }: Pick<EOBForm, 'eob' | 'insurancePayment'>) {
  return {
    eob: {
      ...eob,
      eobServiceCodePaymentInfos: (eob?.eobServiceCodePaymentInfos ?? []).map((item) => {
        const {
          eobId,
          claim,
          id,
          adjustments,
          allowedAmount,
          deductible,
          copay,
          nonCovered,
          coinsurance,
          insurancePayment,
          visitNoteBillingCodeId,
          insuranceWriteOff,
        } = item as ServiceAllocation;
        return {
          eobId,
          claimId: claim?.claimId,
          visitNoteBillingCodeId: visitNoteBillingCodeId ?? id,
          allowedAmount,
          deductible,
          copay,
          nonCovered,
          coinsurance,
          insurancePayment,
          insuranceWriteOff,
          adjustments: serializeAdjustmentCodes(adjustments ?? []),
        };
      }),
    },
    insurancePayment,
  };
}

export function getInReviewInfoForEOB(
  paymentsInfo: EOBServiceCodePaymentInfoViewDTO[],
  patients: PatientCompact[],
): Partial<VisitInfo> {
  const patientsById = keyBy(patients, 'patientId');
  return {
    allocation: values(groupBy(paymentsInfo, 'claim.patientId')).map((groupedInfo) => ({
      adjustments: compact(flatMap(groupedInfo, 'adjustments')),
      patientId: groupedInfo[0]?.claim?.patientId ?? 0,
      patientFullName: patientsById[groupedInfo[0]?.claim?.patientId ?? 0]?.fullName ?? '',
      ...fromPairs(
        [...eobAmountKeys, 'billedAmount', 'insuranceWriteOff'].map((valueKey) => [valueKey, sumBy(groupedInfo, valueKey)]),
      ),
    })),
  };
}

export function getTotalPaid(
  patientPaymentByEncounter: PatientPaymentByEncounterDTO[] = [],
): number {
  return sum((patientPaymentByEncounter ?? [])
    .map((item) => item?.paymentAmount ?? 0));
}

function getAllocatedTotal(data: EOBServiceCodePaymentInfoViewDTO) {
  return sum(values(pick(data ?? {}, keysForAllocatedByType.payment)));
}

function getSuggestedInsuranceWriteoff(
  data: EOBServiceCodePaymentInfoViewDTO,
  billedAmount: number,
) {
  return billedAmount - sum(values(pick(data ?? {}, keysForAllocatedByType.nonCoveredCalculation)));
}

type AllocationMeta = {
  encounterId?: number;
  claim?: ClaimDTO;
  patient?: Partial<PatientCompact>;
};

function getCompactedBreakdown(
  eobBreakdowns: EOBServiceCodePaymentInfoViewDTO[],
): EOBServiceCodePaymentInfoViewDTO {
  const keys = keysForAllocatedByType.payment;
  const result: Record<string, number | null> = {};

  keys.forEach((key) => {
    const values = compact(map(eobBreakdowns, key));
    result[key] = values.length > 0 ? sum(values) : null;
  });

  return result;
}

export function hasAllocationData(allocation: ServiceAllocation[]) {
  const keys = [...eobAmountKeys, 'adjustments'];
  return allocation.some((item) => values(pick(item, keys))
    .some((v) => (Array.isArray(v) ? !isEmpty(v) : !isEmptyString(v))));
}

export function withServiceCodesAllocation(
  codes: Partial<BillingCodeViewDTO & CustomServiceCodeViewDTO>[] = [],
  meta: AllocationMeta = {},
  isOutOfPocket: boolean = false,
  compactEOBPayment: boolean = false,
): ServiceAllocation[] {
  const result = codes.map((item) => {
    const isCPT = !isNil(item.visitNoteBillingCodeId);
    const genericCodeAttributes = isCPT
      ? {
        visitNoteBillingCodeId: item.visitNoteBillingCodeId,
        type: 'cpt',
        id: item.visitNoteBillingCodeId,
        serviceName: item.code ?? '',
        nonCovered: isOutOfPocket ? item.billedAmount : null,
        total: isOutOfPocket ? item.billedAmount : null,
        billedAmount: item?.billedAmount ?? 0,
      } : {
        visitNoteBillingCustomServiceCodeId: item.visitNoteBillingCustomServiceCodeId,
        type: 'custom',
        id: item.visitNoteBillingCustomServiceCodeId,
        serviceName: item.serviceName ?? '',
        nonCovered: item.billedAmount,
        total: item.billedAmount,
      };

    const eobBreakdown = item?.eobServiceCodePaymentInfos ?? [];
    const breakdown = compactEOBPayment && eobBreakdown.length > 1
      ? [getCompactedBreakdown(eobBreakdown)] : eobBreakdown;

    const items = isCPT && !isOutOfPocket && breakdown.length > 0
      ? breakdown.map((info) => ({
        ...info,
        total: getAllocatedTotal(info),
        suggestedInsuranceWriteoff: getSuggestedInsuranceWriteoff(info, item.billedAmount ?? 0),
      })) : [item];

    return items.map((info) => ({
      ...meta,
      patientId: meta?.patient?.patientId ?? 0,
      ...genericCodeAttributes,
      ...info,
    }));
  });
  return result.flat() as ServiceAllocation[];
}

export function withComputedForVisitsNoteBillInfo(
  data: VisitNoteBillInfo[] = [],
): VisitNoteBillInfo[] {
  return data.map(({
    patientTotalResponsibility = 0,
    patientPaid = 0,
    writeOffs,
    encounterId,
    ...info
  }) => {
    const balance = roundNumber(
      patientTotalResponsibility
      - patientPaid
      - sumBy(writeOffs, 'writeOffAmount'),
    );

    return {
      ...info,
      encounterId,
      patientPaid,
      patientTotalResponsibility,
      balance,
      writeOffs: isEmpty(writeOffs)
        ? [{ encounterId }]
        : writeOffs?.map(
          (writeOff) => ({ ...writeOff, encounterId }),
        ),
    };
  });
}

const adjustmentCodesByField: Readonly<
Record<AdjustmentRelatedField, AdjustmentRelatedInfo>> = Object.freeze({
  deductible: {
    groupCode: 'PR',
    reasonCode: '1',
  },
  coinsurance: {
    groupCode: 'PR',
    reasonCode: '2',
  },
  copay: {
    groupCode: 'PR',
    reasonCode: '3',
  },
  insuranceWriteOff: {
    groupCode: 'CO',
    reasonCode: '45',
  },
});

const fieldByAdjustmentCodes = Object.freeze(
  invert(mapValues(adjustmentCodesByField, (info) => `${info?.groupCode}-${info?.reasonCode}`)),
) as Readonly<Record<string, AdjustmentRelatedField>>;

function enhanceAdjustments(
  adjustments: ClaimAdjustment[],
  id: AdjustmentRelatedField,
  amount: number,
): ClaimAdjustment[] {
  const {
    groupCode: groupCodeByField,
    reasonCode: reasonCodeByField,
  } = adjustmentCodesByField[id];

  const adjustmentIndex = adjustments.findIndex(({
    groupCode,
    reasonCode,
  }) => groupCode?.code === groupCodeByField && reasonCode?.code === reasonCodeByField);

  const index = adjustmentIndex === -1 ? adjustments.length : adjustmentIndex ?? 0;

  return [
    ...adjustments.slice(0, index),
    {
      ...(adjustments?.[index] ?? {}),
      groupCode: { code: groupCodeByField },
      reasonCode: { code: reasonCodeByField },
      amount,
    },
    ...adjustments.slice(index + 1),
  ] as ClaimAdjustment[];
}

export function syncWithAdjustments(allocation: ServiceAllocation, id: string): ServiceAllocation {
  const addition: Partial<ServiceAllocation> = {};
  switch (id) {
    case 'adjustments':
      allocation.adjustments?.forEach((adjustment) => {
        const compositeKey = `${adjustment.groupCode?.code}-${adjustment.reasonCode?.code}`;
        const field = fieldByAdjustmentCodes[compositeKey];
        if (typeof field !== 'undefined') {
          addition[field] = Number(adjustment?.amount);
        }
      });
      break;
    case 'coinsurance':
    case 'deductible':
    case 'copay':
    case 'insuranceWriteOff':
      addition.adjustments = enhanceAdjustments(
        allocation?.adjustments ?? [],
        id,
        Number(allocation[id]),
      );
      break;
    default:
      break;
  }

  return {
    ...allocation,
    ...addition,
  };
}

export function populateEOBAllocation(
  eobNumber: string,
  patient: PatientCompact,
  data: EOBDataForClaimViewDTO[],
  allocation: EOBServiceCodePaymentInfoViewDTO[] = [],
): VisitInfo[] {
  const allocationById: Record<number, Record<number, EOBServiceCodePaymentInfoViewDTO>> = {};
  const serviceCodePayments = uniqBy(map(data
    .map(({ billingCodes }) => billingCodes).flat(), 'eobServiceCodePaymentInfos')
    .flat()
    .map(({ adjustments, ...item }) => ({
      ...item,
      adjustments: normalizeAdjustmentCodes(adjustments),
    })), 'eobServiceCodePaymentInfoId');

  const groupedByClaimId = groupBy(allocation, 'claim.claimId');
  forIn(groupedByClaimId, (group, claimId) => {
    allocationById[Number(claimId)] = keyBy(group, 'visitNoteBillingCodeId');
  });

  return data.map((item) => {
    const allocation = withServiceCodesAllocation((item?.billingCodes ?? []).map((code) => ({
      ...code,
      eobServiceCodePaymentInfos: compact([
        allocationById?.[item?.claim?.claimId ?? 0]?.[code?.visitNoteBillingCodeId ?? 0],
      ] ?? []),
    })), {
      encounterId: item?.encounterId ?? 0,
      claim: item?.claim ?? {},
      patient,
    });
    const eobNumbers = new Set(item.eobNumbers ?? []);
    const relatedAllocation = orderBy(serviceCodePayments.filter((payment) => (
      eobNumbers.has(payment.eobNumber)
      && payment.eobNumber !== eobNumber
      && payment.claim?.claimId === item.claim?.claimId
    )).map(({ visitNoteBillingCode, ...payment }) => ({
      ...payment,
      serviceName: visitNoteBillingCode?.code ?? '--',
      billedAmount: visitNoteBillingCode?.serviceCodeDetails?.billedAmount,
    })), ['eobId']);

    return ({
      ...item,
      encounterId: item?.encounterId ?? 0,
      eobNumbers,
      hasEOBs: (item?.eobNumbers ?? []).length > 0,
      patient,
      patientId: patient?.patientId ?? 0,
      reason: item.reasonForVisit ?? '',
      visitDate: item.visitDateTime ?? '',
      formattedVisitDate: formatDate(item?.visitDateTime ?? ''),
      allocation,
      relatedAllocation,
    });
  });
}

export function populateEncounterServicesAllocation(
  data: EncounterFinancialDetailsDTO[],
  patient: PatientCompact,
  currentPaymentByEncounters?: Record<number, PatientPaymentByEncounterDTO>,
): VisitNoteBillInfo[] {
  const result: VisitNoteBillInfo[] = [];
  (data ?? []).forEach(({
    encounterId = 0,
    billingCodes = [],
    customServiceCodes = [],
    encounterDate,
    reasonForVisit,
    patientPayments,
    isOutOfPocket,
    writeOffs,
    copayCollected,
    copay,
    financials,
  }) => {
    const codes = [
      ...(billingCodes ?? []),
      ...(customServiceCodes ?? []),
    ];

    const patientTotalResponsibility = financials?.due ?? 0;
    const balanceWithoutCopay = financials?.balanceWithoutCopay ?? 0;

    const currentPayment = currentPaymentByEncounters?.[encounterId] ?? {};

    const patientCurrentPayment = getTotalPaid(compact([currentPayment]));
    const patientPaid = financials?.paid ?? 0;
    const patientFullName = getFullName(patient);
    result.push({
      encounterId,
      patientId: patient?.patientId ?? 0,
      visitDate: encounterDate ?? '',
      formattedVisitDate: formatDateTime(encounterDate ?? ''),
      reason: reasonForVisit ?? '',
      patientPayments: patientPayments ?? [],
      isOutOfPocket,
      patient,
      patientTotalResponsibility,
      patientPaid,
      patientCurrentPayment,
      balanceWithoutCopay,
      writeOffs,
      copay,
      copayCollected,
      allocation: withServiceCodesAllocation(codes, { encounterId }, isOutOfPocket, true)
        .map((item) => ({ ...item, patientFullName })),
    });
  });

  return withComputedForVisitsNoteBillInfo(result);
}

export function getSelectedEncounterIdsFromPaymentList(selected: Set<string | number>) {
  return Array.from(selected)
    .filter((id) => !Number.isNaN(Number(id))) as number[];
}
