import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query';
import isNil from 'lodash/isNil';
import compact from 'lodash/compact';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import {
  getApiSettingsGetAppointmentTypeSettings,
  postApiSettingsAddAppointmentTypeSetting,
  postApiSettingsAddPayerEntryConfig,
  putApiSettingsUpdatePayerEntryConfig,
  deleteApiSettingsDeletePayerEntryConfig,
  postApiSettingsUpdateAppointmentTypeSetting,
  getApiVisitNoteGetUserFavoriteMedicalCodes,
  postApiOrganizationGetConsentFormEditor,
  deleteApiOrganizationDeleteConsentForm,
  postApiOrganizationAddConsentForm,
  postApiOrganizationUpdateConsentForm,
} from 'endpoints';
import {
  AppointmentTypeSettingDTO,
  DeleteApiSettingsDeletePayerEntryConfigParams,
  ConsentFormEditorDTO,
  DeleteRequestDTO,
  OrganizationalConsentFormsUploadDTO,
  OrganizationalConsentFormDTO,
  PayerEntryConfigDTO,
  UserFavoritedMedicalCodesResponseDTO,
} from 'dtos';

type AppointmentType = {
  appointmentTypes: string[];
  durationByType: Record<string, number>;
};

export function useUpdateConsentForm():
  UseMutationResult<OrganizationalConsentFormDTO, Error, OrganizationalConsentFormDTO> {
  return useMutation<OrganizationalConsentFormDTO, Error, OrganizationalConsentFormDTO>(
    postApiOrganizationUpdateConsentForm,
  );
}

export function useAddConsentForm():
  UseMutationResult<OrganizationalConsentFormDTO, Error, OrganizationalConsentFormsUploadDTO> {
  return useMutation<OrganizationalConsentFormDTO, Error, OrganizationalConsentFormsUploadDTO>(
    postApiOrganizationAddConsentForm,
  );
}

export function useDeleteTemplate():
  UseMutationResult<DeleteRequestDTO, Error, DeleteRequestDTO> {
  return useMutation<DeleteRequestDTO, Error, DeleteRequestDTO>(
    deleteApiOrganizationDeleteConsentForm,
  );
}

export function useGetFormEditor(templateId: string): UseQueryResult<
  ConsentFormEditorDTO, Error> {
  return useQuery<ConsentFormEditorDTO, Error>(
    'consent-form-editor',
    () => postApiOrganizationGetConsentFormEditor({ templateId }),
    { enabled: !isNil(templateId) },
  );
}

export function useUpsertAppointmentType(isNew?: boolean):
  UseMutationResult<AppointmentTypeSettingDTO, Error, AppointmentTypeSettingDTO> {
  return useMutation<AppointmentTypeSettingDTO, Error, AppointmentTypeSettingDTO>(
    (data: AppointmentTypeSettingDTO) => (
      isNew
        ? postApiSettingsAddAppointmentTypeSetting(data)
        : postApiSettingsUpdateAppointmentTypeSetting(data)),
  );
}

export function useGetAppointmentTypeSettings(): UseQueryResult<
  AppointmentTypeSettingDTO[], Error> {
  return useQuery<AppointmentTypeSettingDTO[], Error>(
    ['appointment-type-settings'],
    async () => {
      const response = await getApiSettingsGetAppointmentTypeSettings();
      return response.filter((item) => item?.appointmentType !== 'Time Blocked');
    },
  );
}

export function useGetAppointmentTypes(): AppointmentType {
  const { data } = useGetAppointmentTypeSettings();
  const appointmentTypes = compact(map(data, 'appointmentType'));
  const durationByType = mapValues(
    keyBy(data, 'appointmentType'),
    (item) => item?.defaultDurationInMinutes ?? 15,
  );

  return { appointmentTypes, durationByType };
}

export function useUpsertPayerConfig():
  UseMutationResult<PayerEntryConfigDTO, Error, PayerEntryConfigDTO> {
  return useMutation<PayerEntryConfigDTO, Error, PayerEntryConfigDTO>(
    (payload) => (
      isNil(payload?.payerEntryConfigId)
        ? postApiSettingsAddPayerEntryConfig(payload)
        : putApiSettingsUpdatePayerEntryConfig(payload)
    ),
  );
}

export function useDeletePayerConfig():
  UseMutationResult<void, Error, DeleteApiSettingsDeletePayerEntryConfigParams> {
  return useMutation<void, Error, DeleteApiSettingsDeletePayerEntryConfigParams>(
    deleteApiSettingsDeletePayerEntryConfig,
  );
}

export function useGetFavoriteCodes(): UseQueryResult<UserFavoritedMedicalCodesResponseDTO, Error> {
  return useQuery<UserFavoritedMedicalCodesResponseDTO, Error>(
    ['template-favorite'],
    getApiVisitNoteGetUserFavoriteMedicalCodes,
  );
}
