import React, {
  ReactNode,
  RefObject,
} from 'react';
import clsx from 'clsx';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import MuiDialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {
  Breakpoint,
} from '@mui/material/styles';
import Button from 'pages/Dashboard/components/Button';

type Part = 'dialog' | 'container' | 'bodyRoot' | 'root' | 'title' | 'close';

type Props = { // @TODO: use MUI DialogProps with this props
  className?: string;
  dialogClassName?: string;
  closeBtnClasses?: string; // @TODO: rework this param
  title?: string | ReactNode;
  // @TODO: move all className props to classes
  titleClassName?: string;
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  confirmText?: string; // @TODO: rework this param
  cancelText?: string;
  disableBackdropClick?: boolean;
  disableConfirmButton?: boolean;
  disableEscapeKeyDown?: boolean;
  showActions?: boolean; // @TODO: rework this param
  hideCloseBtn?: boolean; // @TODO: rework this param
  content?: ReactNode;
  children?: ReactNode;
  maxWidth?: false | Breakpoint | undefined;
  fullWidth?: boolean;
  dialogRef?: RefObject<HTMLDivElement>;
  minHeight?: number | string;
  disableEnforceFocus?: boolean;
  classes?: Partial<Record<Part, string>>;
};

export default function Dialog({
  className,
  dialogClassName,
  closeBtnClasses,
  titleClassName,
  title,
  open,
  onClose,
  onConfirm = () => {
  },
  disableBackdropClick,
  disableConfirmButton,
  disableEscapeKeyDown = false,
  confirmText = 'Proceed',
  cancelText = 'Cancel',
  content,
  children,
  showActions,
  hideCloseBtn,
  maxWidth = 'xs',
  fullWidth = false,
  dialogRef,
  minHeight: _minHeight,
  disableEnforceFocus,
  classes,
}: Props) {
  const rootClassName = clsx(
    'p-5 flex flex-col space-y-5 w-[24rem] relative text-sm nw-confirmation-dialog',
    className,
    classes?.root,
  );
  const dialogClasses = clsx(
    'populate-dialog',
    dialogClassName,
    classes?.dialog,
  );
  const header = typeof title === 'string'
    ? <h2 className="text-lg font-semibold mb-3">{title}</h2>
    : title;
  const minHeight = typeof _minHeight === 'number' ? `${_minHeight}px` : _minHeight;

  return (
    <MuiDialog
      className={dialogClasses}
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' && disableBackdropClick) return;
        onClose();
      }}
      classes={{
        paper: clsx('!overflow-y-visible', classes?.bodyRoot),
        container: classes?.container,
      }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      disableEnforceFocus={disableEnforceFocus}
    >
      <div
        className={rootClassName}
        ref={dialogRef}
        style={!isNil(minHeight) ? { minHeight } : undefined}
      >
        {(!isEmpty(title) || !hideCloseBtn) && (
          <div
            className={clsx('flex flex-row justify-between', titleClassName, classes?.title)}
          >
            {header}
            {!hideCloseBtn && (
              <IconButton
                className={clsx('relative -top-3 -right-3 !w-8 !h-8', closeBtnClasses, classes?.close)}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        )}
        {children ?? content}
        {showActions && (
          <div className="flex justify-end items-center space-x-2 nw-confirmation-dialog-buttons">
            <Button
              variant="outlined"
              type="button"
              onClick={onClose}
            >
              {cancelText}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={onConfirm}
              disabled={disableConfirmButton}
            >
              {confirmText ?? 'Proceed'}
            </Button>
          </div>
        )}
      </div>
    </MuiDialog>
  );
}
